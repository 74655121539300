import * as logger from '~/utils/logging'

type Options = {
  start?: 'on' | 'off'
}

export function useStriping(
  component: Ref<ComponentPublicInstance>,
  options?: Options,
) {
  const { start = 'on' } = options || {}

  const onCls = ['use-striping-on', 'bg-neutral-200', 'dark:bg-gray-700']
  const offCls = ['use-striping-off']

  const classes = {
    odd: start === 'on' ? onCls : offCls,
    even: start !== 'on' ? onCls : offCls,
  }

  const apply = () => {
    const $component = unref(component)

    if (!$component) {
      logger.warn('[useStriping] component not found')
      return
    }

    const { $el } = $component

    const $ele = $el || $component

    if (!$ele) {
      logger.warn('[useStriping] $el || $component empty')
      return
    }

    const sections = $ele.children
    if (!sections) return

    $ele.classList.add('use-striping')
    Array.from<HTMLElement>(sections).forEach(
      (element: HTMLElement, idx: number) => {
        const cls =
          idx % 2 === 0
            ? classes.odd.concat('odd')
            : classes.even.concat('even')
        element.classList.add(...cls)
      },
    )
  }

  onMounted(apply)
  onUpdated(apply)
}
